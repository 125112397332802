<form [formGroup]="inquiryForm" InvalidControlScroll>
  <div class="row">
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{inquiryType}}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="inquiryTypeValue.emit(item)" *ngFor="let item of inquiryData?.inquiryType">{{item}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{ selectedBrand ? selectedBrand : 'Jio World Centre' }}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="brandValue.emit(item.value); setDropdownValue('brand', item.value)" *ngFor="let item of generalJWCData?.brand">{{item.label}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="jwc_form_title">
        A few more details and you’ll be all set.
      </div>
    </div>
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="text" name="" value="" placeholder="Name" class="form-control jwc_input"
          formControlName="nameInput" appAllowedInput [alphabetsOnly]="true">
      </div>
      <div *ngIf="(inquiryFormControls.nameInput.touched || submitted) && inquiryFormControls.nameInput.errors">
        <div *ngIf="inquiryFormControls.nameInput.errors.required" class="error-text">{{errorText}}</div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="text" name="" value="Organiser Company" placeholder="Company" class="form-control jwc_input" id=""
          formControlName="companyInput">
      </div>
    </div>
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="email" name="" value="" placeholder="Email" class="form-control jwc_input"
          formControlName="emailInput">
      </div>
      <div *ngIf="(inquiryFormControls.emailInput.touched || submitted) && inquiryFormControls.emailInput.errors">
        <div *ngIf="inquiryFormControls.emailInput.errors.required" class="error-text">{{errorText}}</div>
        <div *ngIf="inquiryFormControls.emailInput.errors.pattern" class="error-text">Email must be a valid email address
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="jwc_form_group">
        <textarea name="" class="form-control jwc_textarea" placeholder="Message" cols="30" rows="10"
          formControlName="messageInput"></textarea>
      </div>
      <div *ngIf="(inquiryFormControls.messageInput.touched || submitted) && inquiryFormControls.messageInput.errors">
        <div *ngIf="inquiryFormControls.messageInput.errors.required" class="error-text">{{errorText}}</div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="custom-control custom-checkbox checkbox jwc_form_group">
        <input type="checkbox" class="custom-control-input" id="dontmiss" name="dontmiss" formControlName="firstConsent">
        <label class="custom-control-label consent" for="dontmiss">
          <span class="text-danger">* </span>
          <span class="ml-1">
            I have read, understood, and accept the Jio World Centre <a href="{{'/legal?tab=privacy'}}" 
            (click)="_sharedService.checkCTALink($event, staticUrlForImgNew + '/legal?tab=privacy', '_blank', 'External')">
            Privacy Policy</a> and consent to sharing of my personal data with Jio World Centre for the purpose of enquiry.
          </span>
        </label>
      </div>
      <div class="error-text" *ngIf="submitted && inquiryFormControls.firstConsent.errors">{{errorText}}</div>
    </div>
    <div class="col-md-12">
      <div class="custom-control custom-checkbox checkbox jwc_form_group">
        <input class="custom-control-input" type="checkbox" id="gridCheckForSecondConsent" formControlName="secondConsent">
        <label class="custom-control-label" for="gridCheckForSecondConsent">
            I agree to receive communications regarding personalized services and other relevant 
            information about Jio World Centre and its affiliated services.
        </label>
      </div>
    </div>
    <div class="col-md-12 captcha">
      <re-captcha (resolved)="captchaRes($event)" [siteKey]="captchaSiteKey"
          (error)="captchaRes($event, true)" errorMode="handled"></re-captcha>
      <div class="error-text" style="margin-top:5px" *ngIf="submitted && !isCaptchaSelected">{{errorText}}</div>
    </div>
  </div>
  <div class="jwc_form_group--btn">
    <button type = "submit" [ngClass]="{'disabled':disableBtn}" [disabled]="disableBtn" class="cta-prim color-gold" (click)="generalHomeSubmit(inquiryForm)"><span
        class="cta-prim-txt">Submit</span></button>
  </div>
  <div class="error-text" style="margin-top:5px" *ngIf="apiErrorMsg">{{apiErrorMsg}}</div>
</form>