<div class="loader">
    <div class="loader_spin"></div>
</div>

<main role="main">
    <app-back-to-wrapper></app-back-to-wrapper>
    <div class="banner_small black_tint"
        [attr.style]="('--data-lineColor:#FFFFFF; --data-titleColor:#FFFFFF') | safe: 'style'">
        <div class="breadcrumbs kma_breadcrumbs">
            <div class="breadcrumbs_container">
                <!-- <a routerLink="/">Jio World Centre</a> / <strong>Contact</strong> -->
                <a *ngIf="page ==='new-das'; else otherLink" routerLink="/das">Dhirubhai Ambani
                    Square</a>
                <ng-template #otherLink>
                    <a routerLink="/">Jio World Centre</a>
                </ng-template> / <strong>Contact</strong>
            </div>
        </div>
        <picture *ngIf="contactUsbannerData">
            <source media="(min-width: 768px)"
                srcset="{{ staticUrlForImgNew + contactUsbannerData?.UploadImageVideo?.document?.contentUrl }}">
            <source media="(max-width: 767px)"
                *ngIf="contactUsbannerData?.UploadMobileKMAImageVideo?.document?.contentUrl"
                srcset="{{ staticUrlForImgNew + contactUsbannerData?.UploadMobileKMAImageVideo?.document?.contentUrl }}">
            <img src="{{ staticUrlForImgNew + contactUsbannerData?.UploadImageVideo?.document?.contentUrl }}"
                class="img-fluid" [alt]="contactUsbannerData?.ImageAltText?.data">
        </picture>
        <h1>{{contactUsbannerData?.Headline?.data}}</h1>
    </div>
    <app-covid2-section *ngIf="contactUsbannerData" [page]="page"></app-covid2-section>
    <div class="jwc_contactus" data-aos="fade-up">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-7">
                    <div class="col-lg-11">
                        <div class="faqs_wrapper contactus_address">
                            <div class="row">
                                <div class="container">
                                    <div id="accordion" class="cus_accordion"
                                        [attr.style]="('--data-faqs-arrowColor: transparent') | safe: 'style'">
                                        <app-address-card *ngIf="contactUsAccordianData"
                                            [addressData]="contactUsAccordianData"></app-address-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-5" *ngIf="contatUsCardData?.HideComponentOnSite?.data==='false'">
                    <div class="container">
                        <div class="row">
                            <div *ngFor="let item of contatUsCardData?.cards; let i = index">
                                <div class="contactus_sidebox"
                                    [attr.style]="('--data-techspecs-textColor:#0F0F0F;') | safe: 'style'">
                                    <h4>{{item?.HeaderText?.data}}</h4>
                                    <p class="content3" [innerText]="item?.ContentText?.data"> </p>
                                    <a *ngIf="item?.HideCTA?.data =='false'" class="cta-sec color-gold"
                                        href="{{item?.CTALink?.data}}"
                                        (click)="callContactUsCTEvent(item?.HeaderText?.data); _sharedService.checkCTALink($event, item?.CTALink?.data, item?.CTAWindowPreference?.data, item?.TypeOfCTALink?.data)">
                                        <span class="cta-sec-txt">{{item?.CTAText?.data}}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="contactus_form" id="contactus-form"
        [attr.style]="('--data-dropdown-hover-border-color:#0f0f0f') | safe: 'style'">
        <div class="container">
            <h4 class="section_heading--title"
                [attr.style]="('--data-lineColor:#A67D54; --data-titleColor:#0F0F0F') | safe: 'style'">Have a
                question for us?</h4>
            <p class="content3">With just a few details, we'll be able to assist you.</p>
            <div class="jwc_form">
                <form [formGroup]="contactUsForm">
                    <div class="form-row">
                        <div class="form-group col-md-6 input-box">
                            <input type="text" class="input-field" placeholder="Name" formControlName="name"
                                appAllowedInput [alphabetsOnly]="true">
                            <div class="error-txt"
                                *ngIf="(contactUsFormControls.name.touched || submitted) && contactUsFormControls.name.errors">
                                {{errorText}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 input-box">
                            <input type="email" class="input-field" placeholder="Email" formControlName="email">
                            <div
                                *ngIf="(contactUsFormControls.email.touched || submitted) && contactUsFormControls.email.errors">
                                <div *ngIf="contactUsFormControls.email.errors.required" class="error-txt">{{errorText}}
                                </div>
                                <div *ngIf="contactUsFormControls.email.errors.pattern" class="error-txt">Email must be
                                    a
                                    valid email address
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <div class="form-row">
                                <div class="col-4 pr-0">
                                    <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                                        <div class="dropdown--select">+91</div>
                                        <div class="dropdown--list" aria-expanded="false" role="list">
                                            <ul>
                                                <li tabindex="0" (click)="setDropdownValue('countryCode', item)"
                                                    *ngFor="let item of countryCode">{{item}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-8 pl-0 input-box">
                                    <input type="text" maxlength="10" class="input-field" placeholder="Phone"
                                        formControlName="phoneNo" appAllowedInput [numbersOnly]="true">
                                    <div class="error-txt"
                                        *ngIf="(contactUsFormControls.phoneNo.touched || submitted) && contactUsFormControls.phoneNo.errors">
                                        Enter 10 digit Mobile Number
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <!-- <ng-container *ngIf="page ==='new-das'; else otherDropdown"> -->
                            <!-- </ng-container> -->
                            <!-- <ng-template #otherDropdown> -->
                            <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                                <div class="dropdown--select">Select a space</div>
                                <div class="dropdown--list" aria-expanded="false" role="list">
                                    <ul>
                                        <li tabindex="0" (click)="setDropdownValue('venue', item.value, item.key)"
                                            *ngFor="let item of venueArr">
                                            {{item.value}}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="error-txt"
                                *ngIf="(contactUsFormControls.venue.touched || submitted) && contactUsFormControls.venue.errors">
                                {{errorText}}
                            </div>
                            <!-- </ng-template> -->
                        </div>
                    </div>
                    <div class="form-row" [ngStyle]="this.venueKey ==='DAS' ? {'visibility': 'visible'} : {'visibility': 'hidden', 'max-height':'0', 'margin-bottom': '0'}">
                        <!-- *ngIf="this.venueKey ==='DAS'" -->
                        <div class="form-group col-md-6">
                            <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                                <!-- DAS live changes -->
                                <!-- <div class="dropdown--select">Select a space</div> -->
                                <div class="dropdown--select">How can we help you?</div>
                                <div class="dropdown--list" aria-expanded="false" role="list">
                                    <ul>
                                        <li tabindex="0" (click)="setDropdownValue('contactQuery', item.value, item.key)"
                                            *ngFor="let item of contactQueryArr">
                                            {{item.value}}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="error-txt"
                                *ngIf="(contactUsFormControls.venue.touched || submitted) && contactUsFormControls.venue.errors">
                                {{errorText}}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-row">
                        <div class="form-group col-md-6">
                            <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                                <div class="dropdown--select">{{topicArr[0]}}</div>
                                <div class="dropdown--list" aria-expanded="false" role="list">
                                    <ul>
                                        <li tabindex="0" (click)="setDropdownValue('topic', item)" *ngFor="let item of topicArr">
                                            {{item}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <p class="content3">Tell us your query in the space provided and our team
                                will get in touch with you.</p>
                            <textarea class="input-field textarea"
                                placeholder="Please write your Question or comment in the space provided" rows="3"
                                formControlName="message"></textarea>
                        </div>
                    </div>
                    <!-- Attachment -->
                    <div class="form-row" *ngIf="this.venueKey ==='DAS'">
                        <div class="col-md-12">
                            <div class="jwc_form_title">
                                Attach a file (pdf/jpg/png/docx/xlsx - up to 5MB)
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="jwc_form_group jwc_file_group">
                                <input type="file" formControlName="fileInput"
                                    (change)="onFileUpload($event.target.files)">
                            </div>
                            <div class="error-text" *ngIf="submitted && contactUsFormControls.fileInput.errors">
                                {{errorText}}</div>
                            <div class="error-text" *ngIf="fileTypeError">{{fileTypeError}}</div>
                            <div class="error-text" *ngIf="fileSizeError">{{fileSizeError}}</div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <!-- <p class="content3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor
                            </p> -->
                            <div class="form-check">
                                <div class="custom-control custom-checkbox checkbox pl-3">
                                    <input class="custom-control-input" type="checkbox" id="gridCheck"
                                        formControlName="firstConsent">
                                    <!-- <ng-container *ngIf="page ==='new-das'; else otherLabel"> -->
                                        <label class="custom-control-label consent" for="gridCheck">
                                                <span class="text-danger">* </span>
                                                <span class="ml-1">
                                                    I have read, understood, and accept the Jio World Centre <a href="{{'/das/legal?tab=privacy'}}" 
                                                    (click)="_sharedService.checkCTALink($event, staticUrlForImgNew + '/das/legal?tab=privacy', '_blank', 'External')">
                                                    Privacy Policy</a> and consent to sharing of my personal data with Jio World Centre for the purpose of enquiry.
                                                </span>
                                        </label>
                                    <!-- </ng-container> -->
                                    <!-- <ng-template #otherLabel>
                                        <label class="custom-control-label consent" for="gridCheck">
                                            <span class="text-danger">* </span>
                                            <span class="ml-1">
                                                I have read, understood, and accept the Jio World Centre <a href="{{'/legal?tab=privacy'}}" 
                                                (click)="_sharedService.checkCTALink($event, staticUrlForImgNew + '/legal?tab=privacy', '_blank', 'External')">
                                                Privacy Policy</a> and consent to the sharing of my personal data for the purpose of applying for jobs at JWC or NMACC. 
                                                I agree that my data is shared with hiring managers considering for potential future purposes.
                                            </span>
                                    </label>
                                    </ng-template> -->
                                    <div class="error-txt" *ngIf="submitted && contactUsFormControls.firstConsent.errors">
                                        {{errorText}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-check">
                                <div class="custom-control custom-checkbox checkbox pl-3">
                                    <input class="custom-control-input" type="checkbox" id="gridCheckForSecondConsent"
                                        formControlName="secondConsent">
                                        <label class="custom-control-label" for="gridCheckForSecondConsent">
                                            I agree to receive communications regarding personalized services and other relevant 
                                            information about Jio World Centre and its affiliated services.
                                        </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Recaptha code -->
                    <div class="form-row">
                        <div class="col-md-12 captcha form-group">
                            <re-captcha (resolved)="contactCaptchaRes($event)" [siteKey]="captchaSiteKey"
                                (error)="contactCaptchaRes($event, true)" errorMode="handled"></re-captcha>
                            <div class="error-txt" style="margin-top:5px" *ngIf="submitted && !isCaptchaSelected">{{errorText}}</div>
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" [disabled]= "disableBtn" (click)="contactUsSubmit(contactUsForm)"
                            class="cta-prim color-gold">
                            <span class="cta-prim-txt">SUBMIT</span>
                    </button>
                    </div>
                    <div class="error-text" style="margin-top:5px" *ngIf="apiErrorMsg">{{apiErrorMsg}}</div>
                </form>
            </div>
        </div>
    </section>
    <!-- <section class="more_centre_wrapper plr-0"
      style="--data-more-centre-bgColor1:#F5F2ED;--data-more-centre-bgColor2: transparent;--data-more-centre-titleColor:#4B4847;
      --data-more_centre_txt_bgColor:#FFFFFF;">
      <div class="container">
  <div class="section_heading text-center" style="--data-titleColor:#4B4847;--data-lineColor:#A67D54">
    <div class="">
      <h2 class="section_heading--title">More at Jio World Centre</h2>
    </div>
  </div>
          <div class="more_centre_slider jwc_swiper_normal" data-interval="8" data-space="24" data-slides="3" data-mob="false" data-ipad-slides="2.2" data-ipad-space="20" data-mobile-slides="1.15" data-mobile-space="20">
              <div class="swiper-container">
                  <div class="swiper-wrapper">
                      <div class="swiper-slide">
                          <div class="more_centre_slider_each">
                              <div class="more_centre_slider_each--img">
                                  <img src="./assets/images/more/1.jpg" class="img-fluid" alt="slider 1">
                              </div>
                              <div class="more_centre_slider_each_copy" style="--data-coloCode:#24A324">
                                  <span class="more_centre_slider_each_copy--category">Food Festival</span>
                                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                                  <span class="more_centre_slider_each_copy--label">Jio World Plaza</span>
                                  <div class="more_centre_slider_each_copy--btns">
                                      <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)" class="cta-prim color-blue icon icon-ticket"><span class="cta-prim-txt">BUY TICKETS</span></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="swiper-slide">
                          <div class="more_centre_slider_each">
                              <div class="more_centre_slider_each--img">
                                  <img src="./assets/images/more/2.jpg" class="img-fluid" alt="slider 1">
                              </div>
                              <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                                  <span class="more_centre_slider_each_copy--category">Performing Arts</span>
                                  <h4 class="more_centre_slider_each_copy--title">Kalasri - Bharatnatyam</h4>
                                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                                  <span class="more_centre_slider_each_copy--label">The Arts Centre</span>
                                  <div class="more_centre_slider_each_copy--btns">
                                      <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)" class="cta-prim color-blue icon icon-ticket"><span
                                              class="cta-prim-txt">BUY TICKETS</span></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="swiper-slide">
                          <div class="more_centre_slider_each">
                              <div class="more_centre_slider_each--img">
                                  <img src="./assets/images/more/3.jpg" class="img-fluid" alt="slider 1">
                              </div>
                              <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                                  <span class="more_centre_slider_each_copy--category">Visual Arts</span>
                                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                                  <span class="more_centre_slider_each_copy--label">The Arts Centre</span>
                                  <div class="more_centre_slider_each_copy--btns">
                                      <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)" class="cta-prim color-blue icon icon-ticket"><span
                                              class="cta-prim-txt">BUY TICKETS</span></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="swiper-arrows">
                  <div class="swiper-button-next"></div>
                  <div class="swiper-button-prev"></div>
              </div>
          </div>
  
  
      </div>
  </section> -->
</main>
<!-- <app-footer [colorList]="contactUsColorList"></app-footer> -->
<div class="overlay"></div>