<form [formGroup]="venueHireGardenInquiryForm" InvalidControlScroll>
  <div class="row">
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{inquiryType}}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="inquiryTypeValue.emit(item)" *ngFor="let item of inquiryData?.inquiryType">{{item}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{ brand }}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="brandValue.emit(item.value)" *ngFor="let item of venueGardenData?.brand">{{item.label}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Type of Events -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{typeOfEvent ? typeOfEvent : 'Type of Event'}}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="setDropdownValue('typeOfEvent', item)" *ngFor="let item of venueGardenArrData?.typeOfEvents">{{item}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6"></div>
    <!-- Date Time Picker and People Range Slider -->
    <div class="col-md-12">
      <div class="jwc_form_title">Enter your event dates to know the availability of the venue</div>
      <div class="row" formGroupName="eventDateTime">
        <div class="col-lg">
          <div class="jwc_form_group jwc_date_time">
            <input type="text" name="" placeholder="From Date" value="From Date" 
              class="form-control jwc_input_date datepicker" id="eventFromDate" data-toggle="datepicker" #eventFromDate
              (click)="updateFromDate(eventFromDate.value, 'eventDateTime', 'fromDateInput')" formControlName="fromDateInput">
            <input type="text" class="form-control jwc_input_time timepicker" formControlName="fromTimeInput" 
              id="fromTime" #eventFromTime (click)="updateFromDate(eventFromTime.value, 'eventDateTime', 'fromTimeInput')">
          </div>
        </div>
        <div class="col-lg">
          <div class="jwc_form_group jwc_date_time">
            <input type="text" name="" placeholder="End Date" value="End Date" 
              class="form-control jwc_input_date datepicker" id="eventToDate" data-toggle="datepicker"
              formControlName="endDateInput" #eventToDate (click)="updateFromDate(eventToDate.value, 'eventDateTime', 'endDateInput')">
            <input type="text" class="form-control jwc_input_time timepicker" value="2:30 AM" #eventToTime 
              formControlName="endTimeInput" (click)="updateFromDate(eventToTime.value, 'eventDateTime', 'endTimeInput')">
          </div>
        </div>
      </div>
      <div *ngIf="eventDateError" class="error-text">{{eventDateError}}</div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="jwc_form_group">
        <label>How many guests are you expecting?</label>
        <input type="text" name="" value="" placeholder="Number of people" class="form-control jwc_input" id="" formControlName="numberOfPeopleInput" appAllowedInput [numbersOnly]="true">
      </div>
    </div>

    <!-- form title -->
    <div class="col-md-12">
      <div class="jwc_form_title">
        A few more details and you’ll be all set
      </div>
    </div>

    <!-- Name -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="text" name="" value="" placeholder="Name" class="form-control jwc_input" id=""
          formControlName="nameInput" appAllowedInput [alphabetsOnly]="true">
      </div>
      <div
        *ngIf="(venueHireGardenFormControls.nameInput.touched || submitted) && venueHireGardenFormControls.nameInput.errors">
        <div *ngIf="venueHireGardenFormControls.nameInput.errors.required" class="error-text">{{errorText}}</div>
      </div>
    </div>

    <!-- Company -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="text" name="" value="" placeholder="Organiser Company" class="form-control jwc_input" id=""
          formControlName="companyInput">
      </div>
    </div>

    <!-- Email -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="email" name="" value="Juraij@gmail.com" placeholder="Email" class="form-control jwc_input" id=""
          formControlName="emailInput">
      </div>
      <div
        *ngIf="(venueHireGardenFormControls.emailInput.touched || submitted) && venueHireGardenFormControls.emailInput.errors">
        <div *ngIf="venueHireGardenFormControls.emailInput.errors.required" class="error-text">{{errorText}}</div>
        <div *ngIf="venueHireGardenFormControls.emailInput.errors.pattern" class="error-text">Email must be a valid email
          address
        </div>
      </div>
    </div>

    <!-- Phone No -->
    <div class="col-md-6">
      <div class="jwc_form_group jwc_form_phn">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">+91</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="setDropdownValue('countryCode', item)" *ngFor="let item of venueGardenArrData?.countryCode">{{item}}</li>
            </ul>
          </div>
        </div>
        <input type="text" name="" value="" placeholder="Phone Number" class="form-control jwc_input" id=""
          formControlName="phoneNoInput" maxlength="10" appAllowedInput [numbersOnly]="true">
      </div>
      <div class="error-text"
        *ngIf="(venueHireGardenFormControls.phoneNoInput.touched || submitted) && venueHireGardenFormControls.phoneNoInput.errors">
        Enter 10 digit Mobile Number</div>
    </div>

    <!-- Message -->
    <div class="col-md-12">
      <div class="jwc_form_group">
        <textarea name="" id="" class="form-control jwc_textarea" placeholder="Message" cols="30" rows="10"
          formControlName="messageInput"></textarea>
      </div>
      <div *ngIf="(venueHireGardenFormControls.messageInput.touched || submitted) && venueHireGardenFormControls.messageInput.errors">
        <div *ngIf="venueHireGardenFormControls.messageInput.errors.required" class="error-text">{{errorText}}</div>
      </div>
    </div>

    <!-- T&C checkbox -->
    <div class="col-md-12">
      <div class="custom-control custom-checkbox checkbox jwc_form_group">
        <input type="checkbox" class="custom-control-input" id="dontmiss" name="dontmiss" formControlName="firstConsent">
        <label class="custom-control-label consent" for="dontmiss">
          <span class="text-danger">* </span>
          <span class="ml-1">
            I have read, understood, and accept the Jio World Centre <a href="{{'/legal?tab=privacy'}}" 
            (click)="_sharedService.checkCTALink($event, staticUrlForImgNew + '/legal?tab=privacy', '_blank', 'External')">
            Privacy Policy</a> and consent to sharing of my personal data with Jio World Centre for the purpose of enquiry.
          </span>
        </label>
      </div>
      <div class="error-text" *ngIf="submitted && venueHireGardenFormControls.firstConsent.errors">{{errorText}}</div>
    </div>

    <div class="col-md-12">
      <div class="custom-control custom-checkbox checkbox jwc_form_group">
        <input class="custom-control-input" type="checkbox" id="gridCheckForSecondConsent" formControlName="secondConsent">
        <label class="custom-control-label" for="gridCheckForSecondConsent">
            I agree to receive communications regarding personalized services and other relevant 
            information about Jio World Centre and its affiliated services.
        </label>
      </div>
    </div>

    <!-- Recaptha code -->
    <div class="col-md-12 captcha">
      <re-captcha (resolved)="vGardenCaptchaRes($event)" [siteKey]="captchaSiteKey"
          (error)="vGardenCaptchaRes($event, true)" errorMode="handled"></re-captcha>
      <div class="error-text" style="margin-top:5px" *ngIf="submitted && !isCaptchaSelected">{{errorText}}</div>
    </div>
  </div>

  <div class="jwc_form_group--btn">
    <button type= "submit" [ngClass]="{'disabled':disableBtn}" [disabled]= "disableBtn" class="cta-prim color-gold" (click)="venueHireGardenSubmit(venueHireGardenInquiryForm)">
      <span class="cta-prim-txt">SUBMIT</span>
    </button>
  </div>
  <div class="error-text" style="margin-top:5px" *ngIf="apiErrorMsg">{{apiErrorMsg}}</div>
</form>