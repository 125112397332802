<form [formGroup]="generalCecInquiryForm" InvalidControlScroll>
  <div class="row">
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{inquiryType}}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="inquiryTypeValue.emit(item)" *ngFor="let item of inquiryData?.inquiryType">{{item}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{ brand }}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="brandValue.emit(item.value)" *ngFor="let item of generalCECData?.brand">{{item.label}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Space checkbox -->
    <div class="col-md-12">
      <div class="jwc_form_title">Select a venue</div>
      <div class="row jwc_form_group">
        <div class="col-md" *ngFor="let item of generalCECArrData?.spaces; let i = index">
          <div class="custom-control custom-checkbox checkbox jwc_form_group">
            <input type="checkbox" class="custom-control-input" [checked] = "spacesArray?.value?.includes(item)" id="{{'checked'+(i+1)}}" name="dontmiss" value={{item}} (change)="onSpaceCheckboxClick($event)">
            <label class="custom-control-label" for="{{'checked'+(i+1)}}">{{item}}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{spaceValue ? spaceValue : 'Select a venue'}}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="setDropdownValue('spaceValue', item)" *ngFor="let item of generalCECArrData?.spaces">{{item}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Type of Events -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select customText">
            {{generalCecFormControls?.typeOfEvent?.value !== (_sharedService.configData.default_enquiries_dropdown_value || 'Did not say')
              ? generalCecFormControls?.typeOfEvent?.value : 'Type of Event'}}
          </div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="setDropdownValue('typeOfEvent', item)" *ngFor="let item of generalCECArrData?.typeOfEvents">
                {{item}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6"></div>

    <!-- Hear about us -->
    <div class="col-md-6">
      <div class="jwc_form_title">
        How did you hear about us?
      </div>
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">Select one</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="setDropdownValue('hearAboutUs', item)" *ngFor="let item of generalCECArrData?.hearAbout">
                {{item}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- form title -->
    <div class="col-md-12">
      <div class="jwc_form_title">
        A few more details and you’ll be all set.
      </div>
    </div>

    <!-- Name -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="text" name="" value="" placeholder="Name" class="form-control jwc_input" id=""
          formControlName="nameInput" appAllowedInput [alphabetsOnly]="true">
      </div>
      <div *ngIf="(generalCecFormControls.nameInput.touched || submitted) && generalCecFormControls.nameInput.errors">
        <div *ngIf="generalCecFormControls.nameInput.errors.required" class="error-text">{{errorText}}</div>
      </div>
    </div>

    <!-- Company -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="text" name="" value="" placeholder="Organiser Company" class="form-control jwc_input" id=""
          formControlName="companyInput">
      </div>
    </div>

    <!-- Email -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="email" name="" value="Juraij@gmail.com" placeholder="Email" class="form-control jwc_input" id=""
          formControlName="emailInput">
      </div>
      <div *ngIf="(generalCecFormControls.emailInput.touched || submitted) && generalCecFormControls.emailInput.errors">
        <div *ngIf="generalCecFormControls.emailInput.errors.required" class="error-text">{{errorText}}</div>
        <div *ngIf="generalCecFormControls.emailInput.errors.pattern" class="error-text">Email must be a valid email
          address
        </div>
      </div>
    </div>

    <!-- Phone No -->
    <div class="col-md-6">
      <div class="jwc_form_group jwc_form_phn">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">+91</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="setDropdownValue('countryCode', item)" *ngFor="let item of generalCECArrData?.countryCode">
                {{item}}</li>
            </ul>
          </div>
        </div>
        <input type="text" name="" value="" placeholder="Phone Number" class="form-control jwc_input" id=""
          formControlName="phoneNoInput" maxlength="10" appAllowedInput [numbersOnly]="true">
      </div>
      <div class="error-text"
        *ngIf="(generalCecFormControls.phoneNoInput.touched || submitted) && generalCecFormControls.phoneNoInput.errors">
        Enter 10 digit Mobile Number</div>
    </div>

    <!-- Message -->
    <div class="col-md-12">
      <div class="jwc_form_group">
        <textarea name="" id="" class="form-control jwc_textarea" placeholder="Message" cols="30" rows="10"
          formControlName="messageInput"></textarea>
      </div>
      <div
        *ngIf="(generalCecFormControls.messageInput.touched || submitted) && generalCecFormControls.messageInput.errors">
        <div *ngIf="generalCecFormControls.messageInput.errors.required" class="error-text">{{errorText}}</div>
      </div>
    </div>

    <!-- T&C checkbox -->
    <div class="col-md-12">
      <div class="custom-control custom-checkbox checkbox jwc_form_group">
        <input type="checkbox" class="custom-control-input" id="dontmiss" name="dontmiss" formControlName="firstConsent">
        <label class="custom-control-label consent" for="dontmiss">
          <span class="text-danger">* </span>
          <span class="ml-1">
            I have read, understood, and accept the Jio World Centre <a href="{{'/legal?tab=privacy'}}" 
            (click)="_sharedService.checkCTALink($event, staticUrlForImgNew + '/legal?tab=privacy', '_blank', 'External')">
            Privacy Policy</a> and consent to sharing of my personal data with Jio World Centre for the purpose of enquiry.
          </span>
        </label>
      </div>
      <div class="error-text" *ngIf="submitted && generalCecFormControls.firstConsent.errors">{{errorText}}</div>
    </div>
    <div class="col-md-12">
      <div class="custom-control custom-checkbox checkbox jwc_form_group">
        <input class="custom-control-input" type="checkbox" id="gridCheckForSecondConsent" formControlName="secondConsent">
        <label class="custom-control-label" for="gridCheckForSecondConsent">
            I agree to receive communications regarding personalized services and other relevant 
            information about Jio World Centre and its affiliated services.
        </label>
      </div>
    </div>

    <!-- Recaptha code -->
    <div class="col-md-12 captcha">
      <re-captcha (resolved)="cecCaptchaRes($event)" [siteKey]="captchaSiteKey"
          (error)="cecCaptchaRes($event, true)" errorMode="handled"></re-captcha>
      <div class="error-text" style="margin-top:5px" *ngIf="submitted && !isCaptchaSelected">{{errorText}}</div>
    </div>
  </div>

  <div class="jwc_form_group--btn">
    <button type= "submit" [ngClass]="{'disabled':disableBtn}" [disabled]= "disableBtn" class="cta-prim color-gold" (click)="generalCecSubmit(generalCecInquiryForm)">
      <span class="cta-prim-txt">SUBMIT</span>
    </button>
  </div>
  <div class="error-text" style="margin-top:5px" *ngIf="apiErrorMsg">{{apiErrorMsg}}</div>
</form>